import { FC, useEffect } from "react"
import { useSentry, SentryLoggerSimple } from "@heyhabito/sentry"

import config from "../../config.json"

import { WrappedBase } from "../shared-components/Shared/WrappedBase"

import { getCookie, removeAuthCookie } from "../utils/auth0"
import { doMSMTag } from "../utils/msm-tag"
import { doMuveTag } from "../utils/muve-tag"
import queryString from "query-string"
import { recentlyVisitedMSMLandingPage } from "../utils/msm-signup-localstorage"

interface GTMEvent {
  event: string
  eventCallback: () => void
  eventTimeout: number
}

declare global {
  interface Window {
    dataLayer?: GTMEvent[]
  }
}

// Before signup the user probably went through the Orientation journey, meaning
// they've answered some questions and we've stored their information in a
// cookie. Now that they've signed up, we want to persist that data against their
// account (the account ID is contained within their token).
const associateOrientationData = (
  token: string,
  logToSentry: SentryLoggerSimple,
  callback: () => void
): void => {
  if (token !== "") {
    fetch(`${config.orientationBaseURL}/associate`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      // The user's answers are contained within a cookie called
      // `orientation-id`, so we must include that in the API request
      credentials: "include",
      body: JSON.stringify({ habitoToken: token }),
    })
      .then(res => {
        // Force the request to show up in dev tools
        res.text()
      })
      .catch(e => {
        logToSentry("error associating orientation data", e)
      })
      .finally(callback)
  } else {
    callback()
  }
}

const SetToken: FC = () => {
  const TIMEOUT = 3000
  let REDIRECTING = false

  const { logToSentry } = useSentry()

  function doRedirect(): void {
    if (REDIRECTING) return

    REDIRECTING = true
    const token = getCookie("authFlows_habitoToken") || ""
    localStorage.setItem("habito/token", token)

    const redirectURI = getCookie("authFlows_loginDestination") || ""

    removeAuthCookie("authFlows_habitoToken")
    removeAuthCookie("authFlows_loginDestination")

    switch (queryString.parse(redirectURI.split("?")[1] || "").from) {
      case "msm":
        associateOrientationData(token, logToSentry, () => {
          doMSMTag(token, logToSentry, () => {
            window.location.replace(redirectURI)
          })
        })
        break
      case "muve":
        associateOrientationData(token, logToSentry, () => {
          doMuveTag(token, logToSentry, () => {
            window.location.replace(redirectURI)
          })
        })
        break
      default:
        // Sometimes people don't hit the "msm" case above (e.g. they
        // generated a magic link from habito.com/sign-in) but we still want
        // to make a best effort to give them an MSM Intercom attribute. Note,
        // this won't work if they click their magic link in a fresh browser.
        if (recentlyVisitedMSMLandingPage()) {
          associateOrientationData(token, logToSentry, () => {
            doMSMTag(token, logToSentry, () => {
              window.location.replace(redirectURI)
            })
          })
        } else {
          associateOrientationData(token, logToSentry, () => {
            window.location.replace(redirectURI)
          })
        }
    }
  }

  useEffect(() => {
    if (
      window !== undefined &&
      document !== undefined &&
      localStorage !== undefined
    ) {
      /*
      Here's something fun. We need to track the 'SignedUp' event through GTM.
      But we can't just send them an event, that would be too easy. Given that
      there is a redirect immediately after, the event is never tracked and
      marketing is very unhappy. We're now trialing 'eventCallback' with a
      global timeout to see if things improve.
    */

      // No matter what happens (ie. GTM is slow to respond and isn't invoking
      // our callback) we set a global timeout to force a redirect either way.
      setTimeout(doRedirect, TIMEOUT)

      // By default, we have nothing to track so we can just invoke the callback
      // immediately.
      let trackSignUp = function (cb: () => void): void {
        cb()
      }

      if (!window.dataLayer) {
        window.dataLayer = []
      }

      // Currently, it'll never be anything else, so this is a safe
      // operation. If the auth flow code wants more query parameters, you
      // will need to update this.
      if (window.location.search == "?new_account=true") {
        trackSignUp = function (cb) {
          if (window.dataLayer !== undefined) {
            window.dataLayer.push({
              event: "SignedUp",
              eventCallback: cb,
              eventTimeout: TIMEOUT,
            })
          }
        }
      }

      trackSignUp(doRedirect)
    }
  })
  return (
    <WrappedBase
      metaTitle="Set token"
      metaDescription=""
      canonicalUrl="https://www.habito.com/set-token"
      noIndex={true}
      pageName="set token"
      intercom={false}
      config={config}
    />
  )
}

export default SetToken

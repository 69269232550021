// In Webflow, on the MSM landing page, we have corresponding code:
// > var now = new Date()
// > localStorage.setItem("habito/msm-lp-visit-time", now.toISOString())
export const recentlyVisitedMSMLandingPage = (): boolean => {
  if (typeof localStorage !== "undefined") {
    const msmVisitTimeIsoString = localStorage.getItem(
      "habito/msm-lp-visit-time"
    )
    if (msmVisitTimeIsoString) {
      const msmLpVisitTime = Date.parse(msmVisitTimeIsoString)
      const now = Date.now()
      const elapsed = now - msmLpVisitTime
      // If mpmLpVisitTimeIsoString is not a valid ISO string, we get NaN, and
      // the comparison evaluates to false.
      return (
        elapsed <
        7 /*days*/ *
          24 /*hours*/ *
          60 /*mins*/ *
          60 /*secs*/ *
          1000 /*milliseconds*/
      )
    } else {
      return false
    }
  } else {
    return false
  }
}

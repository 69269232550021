import { SentryLoggerSimple } from "@heyhabito/sentry"

export const doMSMTag = (
  token: string,
  logToSentry: SentryLoggerSimple,
  callback: () => void
): void => {
  if (token !== "") {
    fetch("/api/habito-one-tagger/tag/msm", {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(res => {
        // Force the request to show up in dev tools
        res.text()
      })
      .catch(e => {
        logToSentry("error in doMSMTag", e)
      })
      .finally(callback)
  } else {
    callback()
  }
}
